/* @font-face */
@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../../fonts/EBGaramond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../../fonts/EBGaramond-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../../fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../../fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../../fonts/Montserrat-Bold.woff') format('woff');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}
