@import 'variables/_variables.scss';

/* BASE */
@import 'tailwindcss/base';
@import 'base/_base.scss';
@import 'base/_fonts.scss';
@import 'base/_reset.scss';
@import 'base/_typo.scss';

/* COMPONENTS */
@import 'tailwindcss/components';

/* UTILITIES */
@import 'tailwindcss/utilities';
